import { getColorFromGradient, rgbToString, tagToColor } from '@discngine/moosa-common';
import React, { memo, useCallback, useMemo } from 'react';

import { useCellRenderer } from '../../SarMatrixContext';
import { SarMatrixCellRendererProps } from '../../SarMatrixTable/types';
import { formatFactor, getAverage } from '../helpers';
import { TooltipContent } from '../TableCellPopover/TooltipContent';

import { CircleChart } from './CircleChart';
import { CircleChartAverageMode } from './CircleChartAverageMode';
import styles from './styles.module.less';

export const CircleCell = memo((props: SarMatrixCellRendererProps) => {
  const {
    isCircleAverage,
    getSubstances,
    selectedCircleColumns,
    rawDataset,
    scoredDataset,
    columnIndices,
    columnLabelMap,
  } = useCellRenderer();

  const ids = useMemo(() => {
    return getSubstances(props).map(({ substanceId }) => substanceId);
  }, [getSubstances, props]);

  const data = useMemo(
    () =>
      ids.map((id: string) => {
        if (selectedCircleColumns[0] && selectedCircleColumns[0].length) {
          const columnId = selectedCircleColumns[0][0].columnId;

          let value = rawDataset[id][columnId];
          const tag = selectedCircleColumns[0][0];

          if (tag.isScoringColumn) {
            value = scoredDataset[id].value;
          }

          if (tag.isScoredColorizingMode) {
            value = scoredDataset[id]?.rawValues[columnIndices[columnId]] ?? 0;
          }

          const indexOfDiscreteValue = tag.discretePropertyValues.findIndex((obj) => {
            switch (typeof value) {
              case 'number':
                return value === Number(obj.value);
              case 'string':
                return value === String(obj.value);
              default:
                return false;
            }
          });

          const tagColor = tag.discretePropertyValues[indexOfDiscreteValue]?.color;

          const isDiscreteValueColored = tag.isDiscreteProperty
            ? Boolean(tagColor)
            : true;

          const isColored = value !== -1 && tag.isColored && isDiscreteValueColored;

          const color = tagToColor({
            value: rawDataset[id]?.[tag.columnId],
            scores: scoredDataset,
            columnIndexes: columnIndices,
            rowId: id,
            tag,
          });

          return {
            id,
            color: isColored ? color : '',
            factor: value,
            label: formatFactor(value),
            columnId,
          };
        }

        return { id, color: '', factor: 0, columnId: '' };
      }),
    [columnIndices, ids, rawDataset, scoredDataset, selectedCircleColumns]
  );

  const averageProps = useMemo(() => {
    let color = '';
    let existingValuesLength = 0;

    if (selectedCircleColumns[0] && selectedCircleColumns[0].length) {
      const values = ids.map(
        (id) => rawDataset[id][selectedCircleColumns[0][0].columnId]
      );
      const existingValues: number[] = values.filter(
        (value): value is number => typeof value === 'number'
      );

      if (existingValues.length === 0) {
        return {
          numberOfExistingScores: 0,
          averagedColor: '',
        };
      }

      const averagedValue = getAverage(existingValues);

      existingValuesLength = existingValues.length;

      const tag = selectedCircleColumns[0][0];
      const currentGradient = tag.isScoredColorizingMode
        ? tag.gradient.scoredGradient
        : tag.gradient.datasetGradient;

      const divider = tag.range.max - tag.range.min || 1;

      const averageFactor = (Number(averagedValue) - tag.range.min) / divider;

      color = `${rgbToString(getColorFromGradient(averageFactor, currentGradient))}`;
    }

    return {
      numberOfExistingScores: existingValuesLength,
      averagedColor: color,
    };
  }, [ids, rawDataset, selectedCircleColumns]);

  const tooltipContent = useCallback(
    (props: { id: string }) => {
      const item = data.find((item) => item.id === props.id);

      return (
        <TooltipContent {...props}>
          {item && (
            <div key={item.id} className={styles.barsRoot}>
              <div className={styles.barPill} style={{ background: item.color }} />

              <div>{columnLabelMap[item.columnId]?.label ?? item.columnId}</div>

              <div className={styles.factorValue}>{item.label}</div>
            </div>
          )}
        </TooltipContent>
      );
    },
    [columnLabelMap, data]
  );

  if (isCircleAverage) {
    return <CircleChartAverageMode {...averageProps} substanceIds={ids} />;
  }

  return <CircleChart data={data} popoverContent={tooltipContent} />;
});
