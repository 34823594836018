import { classNames } from '@discngine/moosa-common';
import { CopyMoleculeButton, RefType } from '@discngine/moosa-shared-components-gen';
import { createRef, FC, useRef, useCallback } from 'react';

import { CellRendererProps } from '../../CellRendererProps';
import { MenuOverlay } from '../../MenuRenderer/MenuRenderer';

import styles from './CellRenderer.module.less';

export const CellRenderer: FC<CellRendererProps> = ({
  menu,
  sarItemData,
  StructureRendererWithPopover,
  className,
  onMoleculeClick,
  onMoleculeHover,
  height,
  width,
  ...props
}) => {
  const copyBtnRef = createRef<RefType>();
  const svgRef = useRef<SVGSVGElement>(null);

  const wrapperClassName = classNames(styles.structure, {
    [className!]: height === undefined && width === undefined,
  });

  const onBlur = useCallback(() => {
    copyBtnRef.current?.onSetCopiedToFalse();
  }, [copyBtnRef]);

  const onMouseLeave = useCallback(() => {
    copyBtnRef.current?.onSetCopiedToFalse();
  }, [copyBtnRef]);

  return (
    <div className={styles.cellRenderer} onBlur={onBlur} onMouseLeave={onMouseLeave}>
      <div
        className={wrapperClassName}
        style={{ height: `${height}px`, width: `${width}px` }}
        onClick={onMoleculeClick}
        onMouseEnter={onMoleculeHover}
      >
        <StructureRendererWithPopover
          smiles={sarItemData.structure}
          svgRef={svgRef}
          {...props}
        />
      </div>
      <MenuOverlay menu={menu} sarItemData={sarItemData} />
      <CopyMoleculeButton
        ref={copyBtnRef}
        className={styles.copy}
        id={sarItemData.rowId}
        structure={sarItemData.structure}
        svgRef={svgRef}
      />
    </div>
  );
};
