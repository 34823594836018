import {
  isNewRowData,
  isRowData,
  isTagData,
  isTagDelimiterData,
  TagData,
  TagId,
  TagsPanel,
} from './types';

const moveArrayItem = <T>(array: T[], fromIndex: number, toIndex?: number): T[] => {
  const updated = [...array];

  const item = updated.splice(fromIndex, 1)[0];

  if (toIndex !== undefined) {
    updated.splice(toIndex > fromIndex ? toIndex - 1 : toIndex, 0, item);
  } else {
    updated.push(item);
  }

  return updated;
};

const swapTags = (tags: TagsPanel, source: TagData, target: TagData): TagsPanel => {
  const updated = structuredClone(tags);

  // Swap source and target tags
  updated[source.rowIndex][source.colIndex] = target.tagId;
  updated[target.rowIndex][target.colIndex] = source.tagId;

  return updated;
};

const moveTag = (
  tags: TagsPanel,
  source: TagData,
  maxInRow: number,
  toRowIndex?: number,
  toColIndex?: number
): TagsPanel => {
  const updated = structuredClone(tags);

  if (source.rowIndex === toRowIndex) {
    // Move inside the same row
    updated[toRowIndex] = moveArrayItem(updated[toRowIndex], source.colIndex, toColIndex);

    return updated;
  }

  // Remove from initial position
  updated[source.rowIndex].splice(source.colIndex, 1);

  if (toRowIndex !== undefined) {
    // Move to an another row
    updated[toRowIndex].splice(
      toColIndex !== undefined ? toColIndex : updated[toRowIndex].length,
      0,
      source.tagId
    );

    // Move excess elements to a new line
    if (updated[toRowIndex].length > maxInRow) {
      const items = updated[toRowIndex].splice(maxInRow);

      updated.splice(toRowIndex + 1, 0, items);
    }
  } else {
    // Insert as a new row
    updated.push([source.tagId]);
  }

  // Remove empty rows
  return updated.filter((row) => row.length > 0);
};

const moveRow = (tags: TagsPanel, rowIndex: number, toRowIndex?: number): TagsPanel => {
  return moveArrayItem(tags, rowIndex, toRowIndex);
};

export const applyDropUpdate = (
  tags: TagsPanel,
  source: unknown,
  target: unknown,
  maxInRow: number
) => {
  // Tag -> Tag
  if (isTagData(source) && isTagData(target)) {
    return swapTags(tags, source, target);
  }

  // Tag -> TagDelimiter
  if (isTagData(source) && isTagDelimiterData(target)) {
    return moveTag(tags, source, maxInRow, target.rowIndex, target.colIndex);
  }

  // Tag -> Row
  if (isTagData(source) && isRowData(target)) {
    return moveTag(tags, source, maxInRow, target.rowIndex, undefined);
  }

  // Tag -> NewRow
  if (isTagData(source) && isNewRowData(target)) {
    return moveTag(tags, source, maxInRow);
  }

  // Row -> Row
  if (isRowData(source) && isRowData(target)) {
    return moveRow(tags, source.rowIndex, target.rowIndex);
  }

  // Row -> NewRow
  if (isRowData(source) && isNewRowData(target)) {
    return moveRow(tags, source.rowIndex);
  }
};

export const deleteTag = (tags: TagsPanel, tagId: TagId): TagsPanel => {
  return tags.reduce<TagsPanel>((acc, tagsRow) => {
    const tagIndex = tagsRow.indexOf(tagId);

    if (tagIndex === -1) {
      acc.push(tagsRow);
    } else {
      const newRow = [...tagsRow];

      newRow.splice(tagIndex, 1);

      // remove empty line
      if (!newRow.length) {
        return acc;
      }
      acc.push(newRow);
    }

    return acc;
  }, []);
};
