import {
  MoosaSarTableConfigGeneric,
  MoosaSarTableConfigProps,
  MoosaSarTablePanelGeneric,
  MoosaSarTablePanelProps,
} from '@discngine/moosa-sar-table-gen';
import { AntComponentsProvider } from '@discngine/moosa-shared-components';

export const MoosaSarTablePanel = (props: MoosaSarTablePanelProps) => (
  <AntComponentsProvider>
    <MoosaSarTablePanelGeneric {...props} />
  </AntComponentsProvider>
);

export const MoosaSarTableConfig = (props: MoosaSarTableConfigProps) => (
  <AntComponentsProvider>
    <MoosaSarTableConfigGeneric {...props} />
  </AntComponentsProvider>
);
