import {
  getRawScoringFactorByColumnId,
  IMoosaSarConfigTag,
  IScoreMap,
  DatasetRowId,
  getSubstanceScoreValue,
  ColumnId,
} from '@discngine/moosa-models';
import clamp from 'lodash/clamp';

import { getColorFromGradient, rgbToString } from './colorUtils';

const NO_COLOR = 'none';

interface TagToColorParams {
  value: string | number | null | undefined;
  scores?: IScoreMap;
  columnIndexes?: Record<ColumnId, number>;
  rowId?: DatasetRowId;
  tag: IMoosaSarConfigTag;
}

export const tagToColor = ({
  value,
  scores = {},
  columnIndexes = {},
  rowId,
  tag,
}: TagToColorParams): string => {
  if (!tag.isColored) {
    return NO_COLOR;
  }

  // Scoring Column
  if (tag.isScoringColumn && rowId !== undefined) {
    const score = getSubstanceScoreValue(scores, rowId);

    return score == null
      ? NO_COLOR
      : rgbToString(getColorFromGradient(score, tag.gradient.scoredGradient));
  }

  // Scoring Colorizing Mode - works the same for numeric and discrete columns
  if (tag.isScoredColorizingMode && rowId !== undefined) {
    const factor = getRawScoringFactorByColumnId(
      scores,
      columnIndexes,
      rowId,
      tag.columnId
    );

    return factor == null
      ? NO_COLOR
      : rgbToString(getColorFromGradient(factor, tag.gradient.scoredGradient));
  }

  // Discrete Column, not Scoring Colorizing Mode
  if (tag.isDiscreteProperty) {
    const paletteValue = tag.discretePropertyValues.find((obj) => {
      switch (typeof value) {
        case 'number':
          return value === Number(obj.value);
        case 'string':
          return value === String(obj.value);
        default:
          return false;
      }
    });
    const tagColor = paletteValue?.color;

    return tagColor ? rgbToString(tagColor) : NO_COLOR;
  }

  // The case of common numeric property
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return NO_COLOR;
  }

  const divider = tag.range.max - tag.range.min || 1;
  const positionInGradient = clamp((numericValue - tag.range.min) / divider, 0, 1);

  return rgbToString(
    getColorFromGradient(positionInGradient, tag.gradient.datasetGradient)
  );
};
