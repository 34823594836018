import {
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import DropIndicator from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { classNames } from '@discngine/moosa-common';
import { FC, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import styles from './DraggableTags.module.less';
import { isRowData, isTagData } from './types';

export const NewRow: FC = () => {
  const ref = useRef(null);

  /** Droppable */
  const [isRowOver, setIsRowOver] = useState<boolean>(false);
  const [isTagOver, setIsTagOver] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    invariant(element);

    return dropTargetForElements({
      element,
      getData: () => ({ type: 'new-row' }),
      canDrop: ({ source }) => isTagData(source.data) || isRowData(source.data),
      onDragEnter: ({ source }) => {
        setIsRowOver(isRowData(source.data));
        setIsTagOver(isTagData(source.data));
      },
      onDragLeave: () => {
        setIsRowOver(false);
        setIsTagOver(false);
      },
      onDrop: () => {
        setIsRowOver(false);
        setIsTagOver(false);
      },
    });
  }, []);
  /** End of Droppable */

  /** Monitor tag dragging */
  const [isTagDragged, setIsTagDragged] = useState<boolean>(false);

  useEffect(() => {
    monitorForElements({
      canMonitor: ({ source }) => isTagData(source.data),
      onDragStart: () => setIsTagDragged(true),
      onDrop: () => setIsTagDragged(false),
    });
  }, []);
  /** End of Monitor */

  return (
    <div
      ref={ref}
      className={classNames(styles.newRow, {
        [styles.isTagDragged]: isTagDragged,
        [styles.isTagOver]: isTagOver,
      })}
    >
      {isRowOver && <DropIndicator edge="top" gap="10px" />}
      Add to new line
    </div>
  );
};
