import { createDragDropManager, DragDropManager } from 'dnd-core';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IsolatedDndProviderProps {
  children: ReactNode;
}

export const IsolatedDndProvider = ({ children }: IsolatedDndProviderProps) => {
  const ref = useRef(null);
  const [manager, setManager] = useState<DragDropManager | null>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    setManager(
      ref.current
        ? createDragDropManager(HTML5Backend, null, { rootElement: ref.current })
        : null
    );
  }, []);

  return (
    <div ref={ref} style={{ width: 'inherit', height: 'inherit' }}>
      {manager && <DndProvider manager={manager}>{children}</DndProvider>}
    </div>
  );
};
