import {
  ColumnColorMultiSelectorGeneric,
  ColumnColorMultiSelectorProps,
} from '@discngine/moosa-shared-components-gen';

import { AntComponentsProvider } from './AntComponentsProvider/AntComponentsProvider';

export const ColumnColorMultiSelector = (props: ColumnColorMultiSelectorProps) => (
  <AntComponentsProvider>
    <ColumnColorMultiSelectorGeneric {...props} />
  </AntComponentsProvider>
);
