import { classNames } from '@discngine/moosa-common';
import {
  DateHistogramData,
  getBarsRerenderForDesirabilityHistogram,
  HistogramData,
} from '@discngine/moosa-histogram';
import {
  ColumnId,
  ConditionType,
  DatasetRowId,
  DecisionTreeChartNode,
  DecisionTreeDateNode,
  DecisionTreeGroupedResult,
  DecisionTreeGroups,
  DecisionTreeId,
  DecisionTreeNode,
  DecisionTreeNodeResult,
  DecisionTreePropertyNode,
  DecisionTreeResult,
  DecisionTreeStructSearchNode,
  DTGroupOption,
  DTNodeId,
  DTNodeType,
  IColumnLabelMap,
  IColumnMetaInfo,
  IDecisionTree,
  IDecisionTreeNew,
  isChartNode,
  IScoringFuncProperty,
  isDateNode,
  isPropertyNode,
  isPropertyLikeNode,
  isStructSearchNode,
  IStructureEditor,
  PortGroupingType,
  DesirabilityFunctionType,
} from '@discngine/moosa-models';
import {
  InfinityListProps,
  IsolatedDndProvider,
} from '@discngine/moosa-shared-components';
import { Button, Layout } from 'antd';
import { PropertyNodeEditPanel } from 'components/PropertyNodeEditPanel/PropertyNodeEditPanel';
import { ReactDiagram } from 'gojs-react';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { DecisionTreeCellValuesWithGroups } from '../../calculateResults/types';
import { CANVAS_LOCATION, DEFAULT_DIAGRAM_STATE } from '../../constants';
import {
  DecisionTree,
  DiagramNodeData,
  DiagramState,
  DropResult,
  MenuItem,
} from '../../types';
import {
  applyNodeUpdatesOnDiagramState,
  createDecisionTreeNode,
  decisionTreeNodeToDiagramNodeData,
  decisionTreeToDiagramState,
  diagramNodeDataToDecisionTreeNode,
  diagramStateToDecisionTree,
  findEmptyPosition,
  getChartNodeHistogram,
  getDesirabilityHistogram,
  getNodeHistogram,
  getTypeMismatch,
  rowsToDateHistogramData,
  rowsToDiscreteHistogramData,
  rowsToNumericHistogramData,
  sanitizeDecisionTree,
} from '../../utils';
import { DecisionTreeSelector } from '../DecisionTreeSelector/DecisionTreeSelector';
import { DiagramWrapper } from '../DiagramWrapper/DiagramWrapper';
import { ReactComponent as CollapseIcon } from '../PropertiesPanel/icons/collapse-shevron.svg';
import { PropertiesPanel } from '../PropertiesPanel/PropertiesPanel';
import { ChartNodeEditPanel } from '../PropertyNodeEditPanel/ChartNodeEditPanel';
import { DateNodeEditPanel } from '../PropertyNodeEditPanel/DateNodeEditPanel/DateNodeEditPanel';
import { StructureNodeEditPanel } from '../PropertyNodeEditPanel/StructureNodeEditPanel';
import { createNewNode } from '../PropertyNodeEditPanel/utils';

import styles from './MoosaDecisionTree.module.less';

export interface MoosaDecisionTreeProps {
  /**
   * Retrieves the menu items for the decision tree node based on nodeId and propertyId of the node.
   *
   * @param nodeId - The decision tree node identifier
   * @param propertyId - The decision tree node property id
   * @returns Array of menu items for the decision tree node.
   */
  getNodeMenu?: (nodeId: DTNodeId, propertyId: ColumnId) => MenuItem[];

  /**
   * Column information from the current dataset
   */
  columnMeta: Record<ColumnId, IColumnMetaInfo>;
  /**
   * ColumnIds define an order of columns in the columns list
   */
  columnIds: ColumnId[];
  /**
   * Mapping between columnID and visible column label. Show columnId if no label
   */
  columnLabelMap?: IColumnLabelMap;

  /**
   * Current Decision tree
   */
  decisionTree: IDecisionTreeNew;
  /**
   * List of decision trees to show in DT selector
   */
  decisionTrees: IDecisionTree[];
  /**
   * List of all versions of the current decision tree to show in DT version selector
   */
  decisionTreeVersions: IDecisionTree[];
  /*
   * The callback is triggered on decisions tree list scroll
   * It is expected that decision tree list will be expanded
   */
  onLoadMore: () => void;
  /*
   * Indicates if next decision tree chunk is loading
   */
  isMoreLoading: boolean;
  /*
   * Value that is used for search through decision trees list
   * */
  search?: string | null;
  /*
   * The callback triggers if enter value in decision tree selector
   */
  onSearchChange: (value: string) => void;

  /**
   * column id to perform grouping in histograms and filtering by group
   * It should be a column with discrete (numeric or string) values
   */
  groupColumnId?: ColumnId | null;
  /**
   * An array of group options. When groupColumnId is not null this property should
   * provide a list of unique values in the column with set of rowIds for each unique value
   */
  groups: DecisionTreeGroups | null;
  /**
   * Selected group or null if no group is selected. It filters DT results by specific group in groupColumnId
   */
  selectedGroup: DTGroupOption | null;
  /**
   * The callback function for selecting a group.
   *
   * @param group - Selected group of null to reset group selection
   */
  onGroupSelect: (group: DTGroupOption | null) => void;

  /**
   * A function to group decision tree results
   * Use /hooks/useGetDecisionTreeGroups.ts as reference implementation
   */
  getDecisionTreeGroupedResult: (
    result: DecisionTreeResult
  ) => DecisionTreeGroupedResult | null;

  /**
   * Default port grouping type - How we display output port for missing values:
   * show/hide missing values port, combine the port with true/false ports
   */
  defaultPortGrouping?: PortGroupingType | null;

  /**
   * A function to calculate decision tree results based on data from current dataset
   * Use /hooks/useGetDecisionTreeResult.ts as reference implementation
   */
  getDecisionTreeResult: (decisionTree: DecisionTree) => DecisionTreeResult;

  /**
   * Retrieves the values of rows specified by their IDs for a given column and the groupColumnId.
   * It is in use when we draw histograms for partially filtered dataset
   *
   * @param rowIds - Set of row IDs.
   * @param columnId - ID of the column to retrieve values from.
   * @returns Array of row values containing group and value properties.
   */
  getRowsValues: (
    rowIds: Set<DatasetRowId>,
    columnId: ColumnId
  ) => DecisionTreeCellValuesWithGroups;

  /**
   * The callback is triggered when user saves current decision tree
   */
  onSave: (decisionTree: IDecisionTreeNew) => void | Promise<void>;
  /**
   * The callback is triggered when the user renames the decision tree.
   * The parent decision tree with the new name will be passed
   */
  onRename: (decisionTree: IDecisionTreeNew) => void | Promise<void>;
  /**
   * The callback is triggered when user resets current decision tree
   */
  onReset: (decisionTreeId?: DecisionTreeId) => void | Promise<void>;
  /**
   * The callback is triggered when user creates a new decision tree
   * It is expected that the decisionTree prop will be updated with a new empty decision tree
   */
  onCreate: () => void | Promise<void>;
  /**
   * The callback is triggered when user selects a new decision tree
   * It is expected that the decisionTree prop will be updated
   */
  onSelect: (decisionTreeId: DecisionTreeId) => void;
  /**
   * The callback is triggered when user resets current decision tree
   * It is expected that decisionTree and decisionTrees props will be updated
   */
  onDelete: (decisionTreeId: DecisionTreeId) => void | Promise<void>;

  /**
   * The callback is triggered by any changes to the decision tree
   *
   * DO NOT pass the decision tree from the callback as a DecisionTree property
   * This will cause a cyclic update
   */
  onChange?: (decisionTree: IDecisionTreeNew) => void;

  /**
   * The callback is triggered when user clicks on a node output results
   * It is expected that a table with selected rowIds will be shown
   */
  onResultsClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void;

  /**
   * GoJS license key
   */
  licenseKey?: string;

  /**
   * Structure editor for Structure search node
   */
  StructureEditor?: IStructureEditor;

  /**
   * Render chem structure to svg string for Structure search node
   * Preferably, render square svg 250x250
   *
   * @param structure - SMILES or Molfile
   * @returns - svg string
   */
  structureToSvg?: (structure: string | null) => string | null;

  /**
   * Properties for custom desirability functions list:
   * data, isLoading, hasMore, loadNext(), revalidate
   */
  desirabilityFunctionListProps?: Partial<InfinityListProps>;

  /**
   * Callback function to save desirability function
   * It is expected that the DesirabilityFunctionListProps list will be updated
   *
   * @param desirability - Desirability function properties
   * @param existingId - Optional ID if updating an existing desirability function
   * @returns - Promise that resolves when the save operation is complete
   */
  onDesirabilitySave: (
    desirability: IScoringFuncProperty,
    existingId?: string
  ) => Promise<void>;

  /**
   * Callback function to perform a search for desirability functions
   * It is expected that the DesirabilityFunctionListProps list will be updated with new items
   *
   * @param query - The search query string
   * @param isDiscreteStringFunction - Indicates whether we are searching by discrete string function
   */
  onDesirabilitySearch: (query: string, isDiscreteStringFunction: boolean) => void;

  /**
   * Callback function to delete a desirability function
   * It is expected that the DesirabilityFunctionListProps list will be updated
   *
   * @param id - The ID of the desirability function to delete
   * @returns - Promise that resolves when the delete operation is complete
   */
  onDesirabilityDelete: (id: string) => Promise<void>;
}

export const MoosaDecisionTree = ({
  getNodeMenu,
  licenseKey,

  columnMeta,
  columnIds,
  columnLabelMap,

  decisionTree,
  decisionTrees,
  decisionTreeVersions,

  groupColumnId,
  defaultPortGrouping,

  groups,
  selectedGroup,
  onGroupSelect,

  getDecisionTreeGroupedResult,
  getDecisionTreeResult,
  getRowsValues,

  onSave,
  onReset,
  onRename,
  onCreate,
  onSelect,
  onDelete,

  onChange,

  onResultsClick,

  onLoadMore,
  isMoreLoading,
  search,
  onSearchChange,

  StructureEditor,
  structureToSvg,

  desirabilityFunctionListProps,
  onDesirabilitySave,
  onDesirabilitySearch,
  onDesirabilityDelete,
}: MoosaDecisionTreeProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const diagramRef = useRef<ReactDiagram>(null);
  const currentDecisionTreeRef = useRef(decisionTree);
  const [diagramState, setDiagramState] = useState<DiagramState>(DEFAULT_DIAGRAM_STATE);
  const [selectedNode, setSelectedNode] = useState<
    | DecisionTreePropertyNode
    | DecisionTreeDateNode
    | DecisionTreeChartNode
    | DecisionTreeStructSearchNode
    | null
  >(null);

  const [, forceRerender] = useReducer((x) => x + 1, 0);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Get metadata for specific column ID
   */
  const getMetaData = useCallback(
    (columnId: ColumnId): IColumnMetaInfo | undefined => {
      return columnMeta[columnId || ''];
    },
    [columnMeta]
  );

  /**
   * Groups decision tree results based on the specified group column ID.
   *
   * @param result - The decision tree result to group.
   * @returns The grouped data.
   */
  const calculateTreeGroups: (
    result: DecisionTreeResult
  ) => DecisionTreeGroupedResult | null = useCallback(
    (result) => {
      if (!groups) return {};

      return getDecisionTreeGroupedResult(result);
    },
    [getDecisionTreeGroupedResult, groups]
  );

  const resetUndoManager = () => {
    const diagram = diagramRef?.current?.getDiagram();

    // set maxHistoryLength to 0 to reset all recent diagram changes
    if (diagram) {
      diagram.startTransaction();
      diagram.undoManager.clear();
      diagram.undoManager.maxHistoryLength = 0;
      diagram.commitTransaction();
    }
  };

  /**
   * Calculates the result of a decision tree based on the provided diagram state.
   *
   * @param diagramState - The diagram state representing the decision tree.
   */
  const calculateTreeResult: (diagramState: DiagramState) => DecisionTreeResult =
    useCallback(
      (diagramState) => {
        return getDecisionTreeResult(diagramStateToDecisionTree(diagramState).data);
      },
      [getDecisionTreeResult]
    );

  /**
   * Calculates the compounds counts for a decision tree property node.
   *
   * @param node - The decision tree property node.
   * @returns The output counts for the node.
   */
  const getNodeResult: (
    node:
      | DecisionTreePropertyNode
      | DecisionTreeChartNode
      | DecisionTreeStructSearchNode
      | DecisionTreeDateNode
  ) => DecisionTreeNodeResult | undefined = useCallback(
    (node) => {
      const result = getDecisionTreeResult(diagramStateToDecisionTree(diagramState).data);

      return result.nodes[node.id];
    },
    [getDecisionTreeResult, diagramState]
  );

  const getAllRows: () => Set<string> = useCallback(() => {
    const result = getDecisionTreeResult(diagramStateToDecisionTree(diagramState).data);

    return result.datasetRowIds;
  }, [getDecisionTreeResult, diagramState]);

  /**
   * Updates the diagram state accordingly with the new `decisionTree`.
   */
  useEffect(() => {
    setDiagramState(decisionTreeToDiagramState(decisionTree, getMetaData));
    // If add getMetaData to the dependency array current unsaved changes will be lost
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionTree]);

  /**
   * Updates the diagram state accordingly with the current decisionTree and new metadata.
   */
  useEffect(() => {
    setDiagramState(
      decisionTreeToDiagramState(currentDecisionTreeRef.current, getMetaData)
    );
  }, [getMetaData]);

  /**
   * Notifies about changes in the diagram state with updated decision tree.
   */
  useEffect(() => {
    const dt = diagramStateToDecisionTree(diagramState, decisionTree);

    currentDecisionTreeRef.current = dt;
    onChange?.(dt);
  }, [decisionTree, diagramState, onChange]);

  /**
   * If the selectedNode is not present in the diagramState's nodes, it clears the selectedNode.
   */
  useEffect(() => {
    if (!selectedNode) return;

    const node = diagramState.nodes.find(({ key }) => key === selectedNode.id);

    if (!node) {
      setSelectedNode(null);

      return;
    }

    const dtNode = diagramNodeDataToDecisionTreeNode(node);

    if (dtNode.type !== DTNodeType.Property || isEqual(dtNode, selectedNode)) {
      return;
    }

    setSelectedNode(dtNode);
  }, [diagramState, selectedNode]);

  /**
   * Updating the diagram state to highlight the currently editable node on the canvas.
   */
  useEffect(() => {
    setDiagramState((diagramState) => ({
      ...diagramState,
      editableNodeId: selectedNode?.id || null,
    }));
  }, [selectedNode?.id]);

  /**
   * A function used to handle saving the diagram state.
   *
   * @param decisionTree - The updated decision tree object.
   */
  const handleSave = useCallback(
    async (decisionTree: IDecisionTreeNew) => {
      setIsLoading(true);
      await onSave(diagramStateToDecisionTree(diagramState, decisionTree));
      diagramRef?.current?.getDiagram()?.undoManager.clear(); // we saved the state, let's clean undo queue
      forceRerender(); // since we keep diagram in ref, we need to force re-render to update the component state
      setIsLoading(false);
    },
    [diagramState, onSave]
  );

  /**
   * A function used to rename the decision tree.
   *
   * @param decisionTree - The updated decision tree object.
   */
  const handleRename = useCallback(
    async (decisionTree: IDecisionTreeNew) => {
      setIsLoading(true);
      await onRename(sanitizeDecisionTree(decisionTree));
      setIsLoading(false);
    },
    [onRename]
  );

  /**
   * A function used to handle reseting the diagram state.
   * Is used to close the node edit panel in addition to resetting the state
   *
   * @param decisionTree - The updated decision tree object.
   */
  const handleReset = useCallback(
    (decisionTreeId?: DecisionTreeId) => {
      setSelectedNode(null);
      onReset(decisionTreeId);

      resetUndoManager();
    },
    [onReset]
  );

  /**
   * A callback function used to update a diagram node based on the provided node data and drop result.
   * It modifies the diagram state by updating or adding the node and creating a new arrow if necessary.
   *
   * @param node - The decision tree node to be updated or added to the diagram.
   * @param result - The drop result containing information about the drop operation.
   */
  const updateNode = useCallback(
    (node: DecisionTreeNode, result?: DropResult) => {
      const port = result?.port;
      const coords = result?.diagramCoords || { x: 0, y: 0 };

      // Move the node down to prevent intersection if dropped onto a port
      if (port) {
        coords.y += 80;
      }

      const metadata = isPropertyLikeNode(node)
        ? getMetaData(node.propertyId)
        : isChartNode(node) && node.columnId !== null
        ? getMetaData(node.columnId)
        : undefined;

      const nodeData = decisionTreeNodeToDiagramNodeData(node, coords, metadata);

      if (!result) {
        nodeData.position = findEmptyPosition(diagramState.nodes);
      }

      if (result?.node && !result.port) {
        nodeData.key = result.node.key;
      }

      setDiagramState((diagramState) => {
        return applyNodeUpdatesOnDiagramState(diagramState, nodeData, port);
      });
    },
    [diagramState.nodes, getMetaData]
  );

  /**
   * Adds an operator node to the diagram.
   *
   * @param nodeType - The type of operator node to add.
   * @param result - The drop result containing information about the drop position.
   */
  const handleOperatorAdd = (
    nodeType:
      | DTNodeType.Or
      | DTNodeType.And
      | DTNodeType.ALN
      | DTNodeType.AMX
      | DTNodeType.Chart,
    result?: DropResult
  ) => {
    const dtNode = createNewNode(nodeType);

    updateNode(dtNode, result);

    if (isChartNode(dtNode)) {
      setSelectedNode(dtNode);
    }
  };

  /**
   * Adds a property node to the diagram.
   *
   * @param propertyId - The ID of the property to add as a node.
   * @param result - The drop result containing information about the drop position.
   */
  const handlePropertyAdd = useCallback(
    (propertyId: ColumnId, result?: DropResult) => {
      const metaData = getMetaData(propertyId);

      const dtNode = createDecisionTreeNode(propertyId, metaData, defaultPortGrouping);

      updateNode(dtNode, result);
      setSelectedNode(dtNode);
    },
    [defaultPortGrouping, getMetaData, updateNode]
  );

  const resetNode = useCallback(
    (node: DecisionTreeNode) => {
      if (!isPropertyLikeNode(node)) return;

      const nodeData = diagramState.nodes.find(({ key }) => key === node.id);

      if (!nodeData) return;

      handlePropertyAdd(node.propertyId, {
        location: CANVAS_LOCATION,
        diagramCoords: null,
        port: null,
        node: nodeData,
      });
    },
    [diagramState.nodes, handlePropertyAdd]
  );

  /**
   * Handles the selection of a node in the diagram.
   *
   * @param node - The selected node in the diagram, or null if no node is selected.
   */
  const onNodeSelect = useCallback((node: DiagramNodeData | null) => {
    const dtNode = node ? diagramNodeDataToDecisionTreeNode(node) : null;

    if (dtNode && (isPropertyLikeNode(dtNode) || isChartNode(dtNode))) {
      setSelectedNode(dtNode);
    } else {
      setSelectedNode(null);
    }
  }, []);

  /**
   * The state of the diagram minimap view.
   */
  const [isOverviewCollapsed, setIsOverviewCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const diagram = diagramRef?.current?.getDiagram();

    // set maxHistoryLength to N to allow move at history by UndoRedo
    if (diagram) {
      diagram.startTransaction();
      diagram.undoManager.maxHistoryLength = 999999999;
      diagram.commitTransaction();
    }
  }, [diagramState]);

  const getDateHistogramData = useCallback(
    (node: DecisionTreeDateNode, rowIds: Set<DatasetRowId>): DateHistogramData | null => {
      let propertyId = node.propertyId;

      if (!propertyId) {
        return null;
      }

      const metaData = getMetaData(propertyId);

      if (!metaData) {
        return null;
      }

      return rowsToDateHistogramData({
        rows: getRowsValues(rowIds, propertyId),
        metaData,
        groupOptions: groups?.options,
      });
    },
    [getMetaData, getRowsValues, groups?.options]
  );

  /**
   * Retrieves the histogram data for a given decision tree node and set of row IDs.
   *
   * @param node - The decision tree node for which to retrieve the histogram data.
   * @param rowIds - Set of row IDs to consider for the histogram data.
   * @returns Histogram data object representing the histogram for the node and row IDs, or null if data is not available.
   */
  const getHistogramData = useCallback(
    (
      node: DecisionTreePropertyNode | DecisionTreeChartNode,
      rowIds: Set<DatasetRowId>
    ): HistogramData | null => {
      let propertyId;

      if (isPropertyNode(node)) {
        propertyId = node.propertyId;
      } else {
        propertyId = node.columnId;
      }

      if (!propertyId) return null;

      const metaData = getMetaData(propertyId);

      if (!metaData) return null;

      let histogramData;
      const typeMismatch = getTypeMismatch(node, metaData);

      // ChartNode node doesn't has a condition
      const isDiscreteHistogram =
        isPropertyNode(node) && !typeMismatch
          ? node.condition?.type === ConditionType.Discrete
          : metaData.isDiscreteColumn;

      const isDesirabilityDiscrete =
        isPropertyNode(node) &&
        node.condition?.type === ConditionType.DesirabilityFunction &&
        node.condition.desirability.type === DesirabilityFunctionType.discrete;

      if (isDiscreteHistogram || isDesirabilityDiscrete) {
        histogramData = rowsToDiscreteHistogramData({
          rows: getRowsValues(rowIds, propertyId),
          discreteValues:
            node.type === DTNodeType.Property &&
            node.condition?.type === ConditionType.Discrete
              ? node.condition.values
              : undefined,
          groupOptions: groups?.options,
        });
      } else {
        histogramData = rowsToNumericHistogramData({
          rows: getRowsValues(rowIds, propertyId),
          metaData,
          groupOptions: groups?.options,
        });
      }

      return histogramData;
    },
    [getMetaData, getRowsValues, groups?.options]
  );

  /**
   * A callback function that calculates the histogram for a given diagram node and dataset row IDs.
   *
   * @param nodeData - The diagram node data.
   * @param rowIds - The set of dataset row IDs.
   * @returns The SVG representation of the histogram, or null if it cannot be generated.
   */
  const getHistogram = useCallback(
    (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>): string | null => {
      const node = diagramNodeDataToDecisionTreeNode(nodeData);

      if (!isPropertyNode(node) && !isDateNode(node) && !isChartNode(node)) return null;

      if (isDateNode(node)) {
        return getNodeHistogram(node, getDateHistogramData(node, rowIds));
      }

      const histogramData = getHistogramData(node, rowIds);

      if (isPropertyNode(node)) {
        if (node.condition?.type === ConditionType.DesirabilityFunction) {
          const metaData = getMetaData(node.propertyId);

          if (!metaData) return null;

          const barsRerender = getBarsRerenderForDesirabilityHistogram(
            node.condition?.desirability.type,
            histogramData
          );

          return getDesirabilityHistogram(node, metaData, barsRerender);
        } else {
          return getNodeHistogram(node, histogramData);
        }
      }

      return getChartNodeHistogram(node, histogramData);
    },
    [getHistogramData, getDateHistogramData, getMetaData]
  );

  // Button 'Save' available only if we can undo the action or generated tree is open
  // (Generated DT is DT with nodes and without id)
  const canSave =
    (!!diagramRef?.current?.getDiagram()?.undoManager.canUndo() ||
      decisionTree._id == null) &&
    diagramState.nodes.length > 0 &&
    !isLoading;

  return (
    <IsolatedDndProvider>
      <div className={styles.root}>
        <Layout className={styles.wrapper}>
          <Layout.Sider
            className={classNames(styles.sidebar, styles.sidebarLeft, {
              [styles.siderCollapsed]: !isSidebarOpen,
            })}
            collapsed={!isSidebarOpen}
            collapsedWidth={0}
            collapsible
            trigger={null}
            width={365}
          >
            <DecisionTreeSelector
              canSave={canSave}
              decisionTree={decisionTree}
              decisionTrees={decisionTrees}
              decisionTreeVersions={decisionTreeVersions}
              isLoading={isMoreLoading}
              search={search}
              onCreate={onCreate}
              onDelete={onDelete}
              onLoadMore={onLoadMore}
              onRename={handleRename}
              onReset={handleReset}
              onSave={handleSave}
              onSearchChange={onSearchChange}
              onSelect={onSelect}
            />

            <PropertiesPanel
              columnIds={columnIds}
              columnLabelMap={columnLabelMap}
              onOperatorAdd={handleOperatorAdd}
              onPropertyAdd={handlePropertyAdd}
            />
          </Layout.Sider>

          <div className={styles.collapse}>
            <Button
              type="primary"
              onClick={() => setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen)}
            >
              <CollapseIcon
                className={`${styles.collapseIcon} ${
                  !isSidebarOpen ? styles.expand : ''
                }`}
              />
            </Button>
          </div>

          <Layout.Content>
            <DiagramWrapper
              key={diagramState.id}
              columnLabelMap={columnLabelMap}
              diagramRef={diagramRef}
              diagramState={diagramState}
              getDiagramResult={calculateTreeResult}
              getGroupedResult={calculateTreeGroups}
              getHistogram={getHistogram}
              getNodeMenu={getNodeMenu}
              groupColumnId={groupColumnId}
              groups={groups}
              isOverviewCollapsed={isOverviewCollapsed}
              licenseKey={licenseKey}
              selectedGroup={selectedGroup}
              onDiagramStateChange={setDiagramState}
              onGroupSelect={onGroupSelect}
              onIsOverviewCollapsedChange={setIsOverviewCollapsed}
              onNodeSelect={onNodeSelect}
              onResultsClick={onResultsClick}
            />
          </Layout.Content>

          {selectedNode && (
            <Layout.Sider
              className={classNames(styles.sidebar, styles.sidebarRight)}
              width={345}
            >
              {isPropertyNode(selectedNode) && (
                <PropertyNodeEditPanel
                  key={selectedNode.id}
                  columnLabelMap={columnLabelMap}
                  columns={columnIds}
                  desirabilityFunctionListProps={desirabilityFunctionListProps}
                  getHistogramData={getHistogramData}
                  getMetaData={getMetaData}
                  getNodeResult={getNodeResult}
                  nodeData={selectedNode}
                  onCancel={() => setSelectedNode(null)}
                  onDesirabilityDelete={onDesirabilityDelete}
                  onDesirabilitySave={onDesirabilitySave}
                  onDesirabilitySearch={onDesirabilitySearch}
                  onNodeChange={updateNode}
                  onReset={resetNode}
                />
              )}
              {isDateNode(selectedNode) && (
                <DateNodeEditPanel
                  key={selectedNode.id}
                  columnLabelMap={columnLabelMap}
                  columns={columnIds}
                  getDateHistogramData={getDateHistogramData}
                  getMetaData={getMetaData}
                  getNodeResult={getNodeResult}
                  nodeData={selectedNode}
                  onCancel={() => setSelectedNode(null)}
                  onNodeChange={updateNode}
                  onReset={resetNode}
                />
              )}
              {isStructSearchNode(selectedNode) && (
                <StructureNodeEditPanel
                  key={selectedNode.id}
                  columnLabelMap={columnLabelMap}
                  columns={columnIds}
                  getMetaData={getMetaData}
                  getNodeResult={getNodeResult}
                  nodeData={selectedNode}
                  StructureEditor={StructureEditor}
                  structureToSvg={structureToSvg}
                  onCancel={() => setSelectedNode(null)}
                  onNodeChange={updateNode}
                  onReset={resetNode}
                />
              )}
              {isChartNode(selectedNode) && (
                <ChartNodeEditPanel
                  key={selectedNode.id}
                  columnLabelMap={columnLabelMap}
                  columns={columnIds}
                  getAllRows={getAllRows}
                  getHistogramData={getHistogramData}
                  getMetaData={getMetaData}
                  getNodeResult={getNodeResult}
                  nodeData={selectedNode}
                  onCancel={() => setSelectedNode(null)}
                  onNodeChange={updateNode}
                />
              )}
            </Layout.Sider>
          )}
        </Layout>
      </div>
    </IsolatedDndProvider>
  );
};
