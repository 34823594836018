import {
  DatasetMutColumn,
  DatasetMutColumnType,
  FieldType,
  IColumnMetaInfo,
  IDatasetMetaStatistics,
  isColumnOfType,
} from '@discngine/moosa-models';

export function humanFileSize(bytes: number) {
  const thresh = 1024;
  let size = bytes;
  const units = ['Kb', 'Mb', 'Gb', 'Tb'];
  let counter = -1;

  if (bytes < thresh) return bytes + ' byte';

  do {
    size /= thresh;
    ++counter;
  } while (size >= thresh && counter < units.length - 1);

  return `${size.toFixed(2)} ${units[counter]}`;
}

const formatter = new Intl.DateTimeFormat('en-GB');

export function fullDateToGBFormat(date: Date) {
  return formatter.format(date);
}

export function convertToOldColumnMeta(metadata: DatasetMutColumn): IColumnMetaInfo {
  const typesMap: Record<DatasetMutColumnType, FieldType | null> = {
    [DatasetMutColumnType.CID]: FieldType.ID,
    [DatasetMutColumnType.arrayOfNumber]: null,
    [DatasetMutColumnType.boolean]: FieldType.String,
    [DatasetMutColumnType.datetime]: FieldType.String,
    [DatasetMutColumnType.molecule]: FieldType.Structure,
    [DatasetMutColumnType.number]: FieldType.Number,
    [DatasetMutColumnType.string]: FieldType.String,
    [DatasetMutColumnType.tags]: null,
    [DatasetMutColumnType.virtual]: null,
  };

  const type = typesMap[metadata.type];

  if (!type) {
    throw new Error(`Unsupported type ${metadata.type}`);
  }

  const statistics: IDatasetMetaStatistics = {
    missingValues: metadata.missingValues ?? 0,
    min: 0,
    max: 0,
    histogramStep: 0,
    histogram: [],
    textCategories: null,
  };
  const oldMeta: IColumnMetaInfo = {
    name: metadata.id,
    type,
    statistics,
    histogramMax: 0,
    histogramBars: metadata.histogram,
    dataWidth: 0,
    isDiscreteColumn: false,
  };

  if (isColumnOfType(metadata, DatasetMutColumnType.number)) {
    Object.assign(statistics, metadata.statistics);
    Object.assign(oldMeta, {
      histogramMax: Math.max(...(metadata.statistics?.histogram ?? [])),
      histogramBars: metadata.histogram,
      dataWidth: (metadata.statistics?.max ?? 0) - (metadata.statistics?.min ?? 0), // statistics.max - statistics.min
      isDiscreteColumn: metadata.isDiscrete,
    });
  } else if (isColumnOfType(metadata, DatasetMutColumnType.string)) {
    Object.assign(statistics, {
      textCategories: metadata.isDiscrete
        ? metadata.discreteStatistics.textCategories.map((category) => ({
            value: category.value ?? '',
            count: category.count,
          }))
        : null,
    });
    Object.assign(oldMeta, {
      histogramBars: metadata.histogram,
      isDiscreteColumn: metadata.isDiscrete,
    });

    oldMeta.statistics = statistics;
  }

  return oldMeta;
}
