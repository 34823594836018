import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteFilled,
  DownloadOutlined,
  DownOutlined,
  DragOutlined,
  EllipsisOutlined,
  HolderOutlined,
  PushpinFilled,
  PushpinOutlined,
  SwapOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  ComponentsContext,
  IComponentsContext,
  InputProps,
  ButtonProps,
  ComponentContextIconsEnum,
  SelectProps,
  CheckboxProps,
} from '@discngine/moosa-shared-components-gen';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import { FC, ReactNode } from 'react';

import { ValueInput } from '../ValueInput/ValueInput';

const CommonInput: FC<InputProps> = (props) => <Input {...props} />;
const CommonButton: FC<ButtonProps> = (props) => <Button {...props} />;

const context: IComponentsContext = {
  Input: CommonInput,
  ValueInput: ValueInput,
  Select: (props: SelectProps) => <Select {...props} onChange={props.onSearch} />,
  Tooltip: Tooltip,
  Button: CommonButton,
  Popover: Popover,
  Row: Row,
  Col: Col,
  Modal: Modal,
  Icons: {
    [ComponentContextIconsEnum.DeleteIcon]: (props) => <DeleteFilled {...props} />,
    [ComponentContextIconsEnum.CheckIcon]: (props) => <CheckOutlined {...props} />,
    [ComponentContextIconsEnum.DownIcon]: (props) => <DownOutlined {...props} />,
    [ComponentContextIconsEnum.CopyIcon]: (props) => <CopyOutlined {...props} />,
    [ComponentContextIconsEnum.EllipsisIcon]: (props) => <EllipsisOutlined {...props} />,
    [ComponentContextIconsEnum.PinIcon]: (props) => <PushpinOutlined {...props} />,
    [ComponentContextIconsEnum.PinFilledIcon]: (props) => <PushpinFilled {...props} />,
    [ComponentContextIconsEnum.MoveIcon]: (props) => <DragOutlined {...props} />,
    [ComponentContextIconsEnum.DragIcon]: (props) => <HolderOutlined {...props} />,
    [ComponentContextIconsEnum.DownloadIcon]: (props) => <DownloadOutlined {...props} />,
    [ComponentContextIconsEnum.UploadIcon]: (props) => <UploadOutlined {...props} />,
    [ComponentContextIconsEnum.CloseIcon]: (props) => <CloseOutlined {...props} />,
    [ComponentContextIconsEnum.SwapIcon]: (props) => <SwapOutlined {...props} />,
  },
  Dropdown: Dropdown,
  Menu: Menu,
  MenuItem: Menu.Item,
  Empty: Empty,
  Radio: Radio.Group,
  Checkbox: (props: CheckboxProps) => (
    <Checkbox {...props} onChange={(event) => props.onChange?.(event.target.checked)} />
  ),
  Upload: Upload,
  message: message,
};

/**
 * AntD implementation of ComponentsContext.
 */
export const AntComponentsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ComponentsContext.Provider value={context}>{children}</ComponentsContext.Provider>
  );
};
