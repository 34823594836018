import { IMoosaSarConfigTag } from '@discngine/moosa-models';
import {
  buildTag,
  MultiplePropertiesSelectorPureGeneric,
  useComponentsContext,
} from '@discngine/moosa-shared-components-gen';
import React, { useCallback } from 'react';

import { MoosaSarConfigCoreGeneric } from './MoosaSarConfigCore/MoosaSarConfigCoreGeneric';
import { MoosaSarConfigImageSizeGeneric } from './MoosaSarConfigImageSizeGeneric';
import { MoosaSarConfigImportExportGeneric } from './MoosaSarConfigImportExportGeneric';
import styles from './MoosaSarTableConfig.module.less';
import { MoosaSarTableConfigProps } from './MoosaSarTableConfigProps';

export const MoosaSarTableConfigGeneric: React.FC<MoosaSarTableConfigProps> = ({
  coreStructures,
  StructureEditor,
  structureRenderer,
  availableColumns,
  columns,
  tableColumnsMap,
  templateColumnsIds,
  isColorizingTextMode,
  imageSize,
  showConfigImportExport = false,
  columnLabelMap,
  setSettings,
  onImageSizeChange,
  onStructureChange,
  onColumnsChange,
  toggleColorizingTableMode,
}) => {
  const { Radio } = useComponentsContext();

  const tagBuilder = useCallback(
    (columnId: string): IMoosaSarConfigTag => {
      return buildTag(columnId, tableColumnsMap, templateColumnsIds);
    },
    [tableColumnsMap, templateColumnsIds]
  );

  return (
    <div className={styles.container}>
      <MoosaSarConfigCoreGeneric
        structure={coreStructures}
        StructureEditor={StructureEditor}
        structureRenderer={structureRenderer}
        onStructureChange={onStructureChange}
      />

      <MultiplePropertiesSelectorPureGeneric
        availableColumns={availableColumns}
        columnLabelMap={columnLabelMap}
        columns={columns}
        tagBuilder={tagBuilder}
        onColumnsChange={onColumnsChange}
      />
      <h3 className={styles.settingTitle}>Colorizing</h3>
      <div>
        <Radio
          options={[
            { value: true, label: 'Text' },
            { value: false, label: 'Background' },
          ]}
          value={isColorizingTextMode}
          onChange={toggleColorizingTableMode}
        />
      </div>

      <h3 className={styles.settingTitle}>Image size</h3>
      <MoosaSarConfigImageSizeGeneric
        currentSize={imageSize}
        onChange={onImageSizeChange}
      />

      {showConfigImportExport && (
        <>
          <hr />
          {/* TODO: <Divider /> */}
          <MoosaSarConfigImportExportGeneric
            columns={columns}
            imageSize={imageSize}
            isColorizingTextMode={isColorizingTextMode}
            setSettings={setSettings}
            structure={coreStructures}
          />
        </>
      )}
    </div>
  );
};
