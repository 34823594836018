import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { classNames } from '@discngine/moosa-common';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import styles from './DraggableTags.module.less';
import { DraggableState, isTagData, TagId, TagRenderer } from './types';

interface TagProps {
  tagId: TagId;
  rowIndex: number;
  colIndex: number;
  TagRenderer: TagRenderer;
  onDelete: (tagId: TagId) => void;
}

export const Tag: FC<TagProps> = ({
  tagId,
  rowIndex,
  colIndex,
  TagRenderer,
  onDelete,
}) => {
  const ref = useRef(null);

  /** Draggable */
  const [draggableState, setDraggableState] = useState<DraggableState>('idle');

  useEffect(() => {
    const element = ref.current;

    invariant(element);

    return draggable({
      element,
      onDragStart: () => setDraggableState('dragging'),
      onDrop: () => setDraggableState('idle'),
      getInitialData: () => ({ type: 'tag', tagId, rowIndex, colIndex }),
    });
  }, [colIndex, rowIndex, tagId]);
  /** End of Draggable */

  /** Droppable */
  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    invariant(element);

    return dropTargetForElements({
      element,
      getData: () => ({ type: 'tag', tagId, rowIndex, colIndex }),
      canDrop: ({ source }) => isTagData(source.data) && source.data.tagId !== tagId,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [colIndex, rowIndex, tagId]);
  /** End of Droppable */

  const onRemove = useCallback(() => {
    onDelete(tagId);
  }, [onDelete, tagId]);

  return (
    <div
      ref={ref}
      className={classNames(styles.tag, {
        [styles.isDraggedOver]: isDraggedOver,
        [styles.dragging]: draggableState === 'dragging',
      })}
    >
      <TagRenderer tagId={tagId} onRemove={onRemove} />
    </div>
  );
};
