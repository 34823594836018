import {
  ColorPickerPureGeneric,
  ColorPickerPureProps,
} from '@discngine/moosa-shared-components-gen';

import { AntComponentsProvider } from './AntComponentsProvider/AntComponentsProvider';

export const ColorPickerPure = (props: ColorPickerPureProps) => (
  <AntComponentsProvider>
    <ColorPickerPureGeneric {...props} />
  </AntComponentsProvider>
);
