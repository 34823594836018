import { IScoringTemplateState, useMoosaDataContext } from '@discngine/moosa-models';
import { setScoringTemplateState } from '@discngine/moosa-store/scoringTemplate';
import { selectTableColumnNames } from '@discngine/moosa-store/tableConfig';
import { selectTableId } from '@discngine/moosa-store/tableInfo';
import { MoosaTemplatesWidget } from '@discngine/moosa-templates-widget';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateChartAfterAction } from '../../store/charts/charts.hook';

export const TemplatesPanel: FC = function TemplatesPanel() {
  const dispatch = useDispatch();
  const dataService = useMoosaDataContext();
  const datasetId = useSelector(selectTableId);

  const updateChartAfterAction = useUpdateChartAfterAction();

  const onSelectTemplate = useCallback(
    (scoringTemplate: IScoringTemplateState) => {
      console.info(`onSelectTemplate`, scoringTemplate);
      dispatch(
        setScoringTemplateState({
          templateState: scoringTemplate,
        })
      );

      updateChartAfterAction();
    },
    [dispatch, updateChartAfterAction]
  );

  const onCreateNewTemplate = useCallback(
    (scoringTemplate: IScoringTemplateState) => {
      console.info(`template is created`, scoringTemplate);

      dispatch(setScoringTemplateState({ templateState: scoringTemplate }));
      updateChartAfterAction();
    },
    [dispatch, updateChartAfterAction]
  );

  const onDeleteTemplate = useCallback(
    (scoringTemplate: IScoringTemplateState) => {
      console.info(`template is deleted`, scoringTemplate);
      dispatch(setScoringTemplateState({ templateState: scoringTemplate }));
      updateChartAfterAction();
    },
    [dispatch, updateChartAfterAction]
  );

  const onTemplateChange = useCallback(
    (scoringTemplate: IScoringTemplateState) => {
      console.info(`onTemplateChange`, scoringTemplate);
      dispatch(
        setScoringTemplateState({
          templateState: scoringTemplate,
        })
      );
      updateChartAfterAction();
    },
    [dispatch, updateChartAfterAction]
  );

  const columnNames = useSelector(selectTableColumnNames);

  return (
    <MoosaTemplatesWidget
      columnNames={columnNames}
      dataService={dataService}
      datasetId={datasetId}
      onCreateNewTemplate={onCreateNewTemplate}
      onDeleteTemplate={onDeleteTemplate}
      onSelectTemplate={onSelectTemplate}
      onTemplateChange={onTemplateChange}
    />
  );
};
