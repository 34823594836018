import React from 'react';
import { forwardRef, RefObject, useCallback, useImperativeHandle, useState } from 'react';

import { useComponentsContext } from '../ComponentsContext/ComponentsContext';

import styles from './CopyMoleculeButton.module.less';
import { useCopy } from './useCopy';

interface CopyMoleculeButtonProps {
  id: string;
  structure: string;
  svgRef: RefObject<SVGSVGElement>;
  className: string;
}
export interface RefType {
  onSetCopiedToFalse: () => void;
}

/**
 * You need to use this component with a CSS class wrapper that provides the following css variables:
 * --copy-button-color: @grey-4;
 * --copy-button-hover-color: @dng-dark-blue;
 * --copy-button-active-color: @dng-dark-blue;
 *
 * Example: colorizingPanelColors class in ColorizingPanel (moosa-shared-components)
 **/
export const CopyMoleculeButton = forwardRef<RefType, CopyMoleculeButtonProps>(
  ({ className, svgRef, id, structure }, ref) => {
    const {
      Button,
      Icons: { CheckIcon, CopyIcon },
    } = useComponentsContext();

    const [copied, setCopied] = useState(false);
    const onSetCopiedToFalse = () => {
      setCopied(false);
    };

    useImperativeHandle(ref, () => ({ onSetCopiedToFalse }));

    const copy = useCopy();

    const handleCopy = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        copy(structure, svgRef?.current?.outerHTML ?? '', id ?? '');
        setCopied(true);
      },
      [copy, id, structure, svgRef]
    );

    const style = `${styles.root} ${styles.copyButton} ${
      copied ? styles.copyButtonActive : null
    } ${className}`;

    return (
      <Button
        className={style}
        icon={copied ? <CheckIcon /> : <CopyIcon />}
        size="small"
        type="text"
        onClick={handleCopy}
      />
    );
  }
);
