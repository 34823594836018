import { classNames } from '@discngine/moosa-common';
import React, { FC } from 'react';

import { useComponentsContext } from '../ComponentsContext/ComponentsContext';

import styles from './TagWithCaption.module.less';
import { TagRendererProps } from './types';

export interface TagWithCaptionProps extends TagRendererProps {
  tagLabel?: string;
  children?: React.ReactNode;
}

export const TagWithCaption: FC<TagWithCaptionProps> = ({
  tagId,
  tagLabel,
  onRemove,
  children,
  className,
}) => {
  const {
    Tooltip,
    Icons: { CloseIcon },
  } = useComponentsContext();

  const label = tagLabel || tagId;

  const tagNameTooltip =
    !tagLabel || tagId === tagLabel ? tagId : `${tagId} / ${tagLabel}`;

  return (
    <div className={classNames(className, styles.tagElement)}>
      {children}
      <Tooltip
        // TODO:
        // mouseEnterDelay={0.4}
        // mouseLeaveDelay={0}
        // style={{ pointerEvents: 'none' }}
        title={tagNameTooltip}
      >
        <span className={styles.tagLabel}>{label}</span>
      </Tooltip>
      <CloseIcon className={styles.tagClose} onClick={onRemove} />
    </div>
  );
};
