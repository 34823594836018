import { DownOutlined, UpOutlined } from '@ant-design/icons/lib';
import { COMPARISON_SCORE_COLUMN_ID } from '@discngine/moosa-models';
import { IsolatedDndProvider } from '@discngine/moosa-shared-components';
import { Input } from 'antd';
import React, { useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from './Columns.module.less';
import { ColumnViewItem, IColumnViewItem } from './ColumnViewItem';

export type IColumnsProps = {
  columns: IColumnViewItem[];
};

export const Columns: React.FC<IColumnsProps> = ({ columns }) => {
  const systemColumns = columns.filter((x) => x.isSystem);
  const datasetColumns = columns.filter((x) => !x.isSystem);
  const [filter, setFilter] = useState('');
  const [isSystemColumnsOpen, setIsSystemColumnsOpen] = useState(true);
  const [isDatasetColumnsOpen, setIsDatasetColumnsOpen] = useState(true);

  const showColumns = systemColumns.length + datasetColumns.length !== 0;

  const filteredColumns = useMemo(
    () =>
      datasetColumns.filter(
        ({ columnName, colId }) =>
          columnName.toUpperCase().includes(filter.toUpperCase()) &&
          colId !== COMPARISON_SCORE_COLUMN_ID
      ),
    [datasetColumns, filter]
  );

  return (
    <>
      <Input.Search
        className={styles.filterInput}
        placeholder="Type filter name or choose"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      />
      <IsolatedDndProvider>
        {showColumns && (
          <PerfectScrollbar
            className={styles.visibilityViewScroll}
            options={{ suppressScrollX: true }}
          >
            <div className={styles.dropDownTitle}>
              <h3 className={styles.columnsGroupTitle}>System columns</h3>
              <div
                className={styles.dropDownBtn}
                onClick={() => setIsSystemColumnsOpen(!isSystemColumnsOpen)}
              >
                {isSystemColumnsOpen ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {isSystemColumnsOpen && (
              <ul className={styles.visibilityViewList}>
                {systemColumns.map((column) => (
                  <ColumnViewItem
                    key={column.colId}
                    colId={column.colId}
                    color={column.color}
                    columnName={column.columnName}
                    isAlwaysVisible={column.isAlwaysVisible}
                    isNumber={column.isNumber}
                    isPinned={column.isPinned}
                    isSystem={column.isSystem}
                    isVisible={column.isVisible}
                    tableId={column.tableId}
                    onChangeColumnOrder={column.onChangeColumnOrder}
                    onChangeVisibility={column.onChangeVisibility}
                    onColorChange={column.onColorChange}
                  />
                ))}
              </ul>
            )}
            <div className={styles.dropDownTitle}>
              <h3 className={styles.columnsGroupTitle}>Data Set Columns</h3>
              <div
                className={styles.dropDownBtn}
                onClick={() => setIsDatasetColumnsOpen(!isDatasetColumnsOpen)}
              >
                {isDatasetColumnsOpen ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {isDatasetColumnsOpen && (
              <ul className={styles.visibilityViewList}>
                {filteredColumns.map((column) => (
                  <ColumnViewItem
                    key={column.colId}
                    colId={column.colId}
                    color={column.color}
                    columnName={column.columnName}
                    isAlwaysVisible={column.isAlwaysVisible}
                    isNumber={column.isNumber}
                    isPinned={column.isPinned}
                    isSystem={column.isSystem}
                    isVisible={column.isVisible}
                    tableId={column.tableId}
                    onChangeColumnOrder={column.onChangeColumnOrder}
                    onChangeVisibility={column.onChangeVisibility}
                    onColorChange={column.onColorChange}
                  />
                ))}
              </ul>
            )}
          </PerfectScrollbar>
        )}
      </IsolatedDndProvider>

      {!showColumns && (
        <div className={styles.noFields}>no fields matching the filter</div>
      )}
    </>
  );
};
