import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import DropIndicator from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { FC, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import styles from './DraggableTags.module.less';
import { isTagData } from './types';

interface TagDelimiterProps {
  rowIndex: number;
  colIndex: number;
}

export const TagDelimiter: FC<TagDelimiterProps> = ({ rowIndex, colIndex }) => {
  const ref = useRef(null);

  /** Droppable */
  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    invariant(element);

    return dropTargetForElements({
      element,
      getData: () => ({ type: 'tag-delimiter', rowIndex, colIndex }),
      canDrop: ({ source }) => isTagData(source.data),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [colIndex, rowIndex]);
  /** End of Droppable */

  return (
    <div ref={ref} className={styles.tagDelimiter}>
      {isDraggedOver && <DropIndicator edge="left" gap="-8px" />}
    </div>
  );
};
