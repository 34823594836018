import { Expression } from 'estree';

import { IDatasetMetaStatistics } from '../../serverModels/IDatasetMetaModel';
import { Formula } from '../../serverModels/IFormula';

import { IHistogramBar } from './tableInfo';

export type DatasetMutServer = DatasetMut;
export interface DatasetMut {
  _id: string;
  name: string;
  description: string;
  columns: DatasetMutColumn[];
  columnGroups?: DatasetMutColumnGroup[];
  rows: DatasetMutRow[];

  missingValues: number;
  deletedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  rowCount: number;
}

export type DatasetMutRowId = string & { _type?: 'DatasetMutRowId' };
export type DatasetMutColumnId = string & { _type?: 'DatasetMutColumnId' };
export type DatasetMutRowGroupId = (string & { _type?: 'DatasetMutRowGroupId' }) | null;
export type DatasetMutColumnGroupId = string & {
  _type?: 'DatasetMutColumnGroupId';
};

export interface DatasetMutRow {
  _id: DatasetMutRowId;
  // tags: string[];
  properties: Record<DatasetMutColumnId, AllColumnTypes>; // null - missing value
}

/**
 * @deprecated
 */
export type DatasetMutCellValue =
  | string
  | number
  | boolean
  | Date
  | object
  | string[]
  | null;

export interface DatasetMutCell<T = DatasetMutCellValue> {
  v: T;
  q?: string; // Qualifier --?? > 1000 - ignore qualifier, treat as exact value
  u?: string; // Unit - redundant?
  a?: string; // aggregation
  raw?: T[];
}

export enum DatasetMutColumnType {
  CID = 'CID', // required
  string = 'string',
  number = 'number',
  arrayOfNumber = 'arrayOfNumber',
  boolean = 'boolean',
  datetime = 'datetime',
  molecule = 'molecule',
  tags = 'tags',
  // The column exists only in config, not in dataset. The column has subtype
  virtual = 'virtual',
}

export type ColumnTypeToDataMapper = {
  [DatasetMutColumnType.molecule]: string | null;
  [DatasetMutColumnType.number]: DatasetMutCell<number | null> | null;
  [DatasetMutColumnType.string]: string | null;
  [DatasetMutColumnType.CID]: string | number | null;
  [DatasetMutColumnType.datetime]: number | null;
  [DatasetMutColumnType.boolean]: boolean | null;
  [DatasetMutColumnType.arrayOfNumber]: number[] | null;
  [DatasetMutColumnType.tags]: string[] | null;
  [DatasetMutColumnType.virtual]: null; // no own values, values will be defined by renderer
};

export const COLUMN_TYPE_READABLE_NAME: Record<DatasetMutColumnType, string> = {
  [DatasetMutColumnType.arrayOfNumber]: 'Array of numbers',
  [DatasetMutColumnType.tags]: 'Tags',
  [DatasetMutColumnType.boolean]: 'Boolean',
  [DatasetMutColumnType.CID]: 'CID',
  [DatasetMutColumnType.string]: 'String',
  [DatasetMutColumnType.datetime]: 'Datetime',
  [DatasetMutColumnType.molecule]: 'Molecule',
  [DatasetMutColumnType.number]: 'Number',
  [DatasetMutColumnType.virtual]: 'Virtual',
};

export type ColumnTypeToDataType<T extends DatasetMutColumnType> =
  T extends DatasetMutColumnType.number ? number | null : ColumnTypeToDataMapper[T];

/**
 * This type works as validator to check that ColumnTypeToDataMapper covers all DatasetMutColumnType
 */
export type AllColumnTypes = ColumnTypeToDataMapper[DatasetMutColumnType];

export interface DatasetMutColumnGroup {
  id: DatasetMutColumnGroupId;
  name: string;
  // columnKeys: string[];
}

export type DatasetMutColumn<Type extends DatasetMutColumnType = DatasetMutColumnType> =
  Extract<DatasetMutServerColumns | DatasetMutVirtualColumn, { type: Type }>;

export type RowSelectionKey = (
  | `${DatasetMutRowId}:${DatasetMutRowGroupId}`
  | `${DatasetMutRowId}`
) & {
  _type?: 'RowSelectionKey';
};

export const getRowSelectionKey = (
  rowId: DatasetMutRowId,
  groupId?: DatasetMutRowGroupId
): RowSelectionKey => {
  return (groupId !== undefined ? `${rowId}:${groupId}` : `${rowId}`) as RowSelectionKey;
};

export interface DatasetMutRowGroup {
  id: DatasetMutRowGroupId;
  subRows: DatasetMutRowId[];
}

export type DatasetMutServerColumns =
  | DatasetMutMoleculeColumn
  | DatasetMutStringColumn
  | DatasetMutNumericColumn
  | DatasetMutBooleanColumn
  | DatasetMutTagsColumn
  | DatasetMutCIDColumn
  | DatasetMutDateColumn
  | DatasetMutArrayOfNumbersColumn;

/**
 * Common fields across all column metadata
 */
export interface DatasetMutColumnCommon<T extends DatasetMutColumnType> {
  id: DatasetMutColumnId;
  label: string;
  columnGroupId?: DatasetMutColumnGroupId;
  type: T;
  uom?: string; // Unit of measurment

  missingValues: number;

  histogram: IHistogramBar[]; // TODO move to relevant types! it is here just for compatibility with old interface
}

export interface ICategory<T extends string | number | boolean> {
  value: T | null; // null for missing values
  count: number;
}

type DiscreteColumn<T extends string | number | boolean> = {
  isDiscrete: true;
  discreteStatistics: {
    // only for discrete column
    textCategories: ICategory<T>[];
  };
};

type MaybeDiscreteColumn<T extends string | number | boolean> =
  | DiscreteColumn<T>
  | {
      isDiscrete: false;
    };

export interface IComputedFormula extends Formula {
  expression: Expression;
  usedColumns: DatasetMutColumnId[];
}

type MaybeComputedColumn =
  | {
      isComputed: false;
      formula?: undefined;
    }
  | {
      isComputed: true;
      formula: IComputedFormula;
    };

// never discrete
// never computed
export type DatasetMutMoleculeColumn =
  DatasetMutColumnCommon<DatasetMutColumnType.molecule>;

export type DatasetMutStringColumn = DatasetMutColumnCommon<DatasetMutColumnType.string> &
  MaybeDiscreteColumn<string> &
  MaybeComputedColumn;

export type DatasetMutNumericColumn =
  DatasetMutColumnCommon<DatasetMutColumnType.number> &
    MaybeDiscreteColumn<number> &
    MaybeComputedColumn & {
      statistics: IDatasetMetaStatistics; // TODO to be refactored
    };

// Always discrete
// computed
export type DatasetMutBooleanColumn =
  DatasetMutColumnCommon<DatasetMutColumnType.boolean> &
    DiscreteColumn<boolean> &
    MaybeComputedColumn;

// always "like to discrete" - has statistics on tags and a list of tags
// not computed
export type DatasetMutTagsColumn = DatasetMutColumnCommon<DatasetMutColumnType.tags> &
  DiscreteColumn<string>;

export type DatasetMutCIDColumn = DatasetMutColumnCommon<DatasetMutColumnType.CID> & {
  // TODO not in use right now
  isNumeric?: boolean; // treat data as numbers or as strings (e.g., for sorting)?
};

// never discrete
// never computed
export type DatasetMutDateColumn = DatasetMutColumnCommon<DatasetMutColumnType.datetime> &
  MaybeComputedColumn & {
    // Do we need statistics on dates?
    statistics: IDatasetMetaStatistics; // TODO to be refactored
  };

// no statistics
// never discrete
// never computed
export type DatasetMutArrayOfNumbersColumn =
  DatasetMutColumnCommon<DatasetMutColumnType.arrayOfNumber>;

// no statistics
// never discrete
// never computed
export type DatasetMutVirtualColumn =
  DatasetMutColumnCommon<DatasetMutColumnType.virtual> & {
    // these fields define a subtype of the virtual column
    schema?: string;
    schemaVersion?: string;
  };

/**
 *  TYPE GUARDS
 */
export function isColumnOfType<Type extends DatasetMutColumnType = DatasetMutColumnType>(
  metadata: DatasetMutColumn | undefined,
  columnType: Type
): metadata is DatasetMutColumn<Type> {
  return metadata?.type === columnType;
}

export type DatasetMutColumnCanBeDiscrete =
  | DatasetMutColumnType.string
  | DatasetMutColumnType.number
  | DatasetMutColumnType.boolean
  | DatasetMutColumnType.tags; // TODO Use separate interface for tags???

export function isPossiblyDiscreteColumn(
  metadata: DatasetMutColumn | undefined
): metadata is DatasetMutColumn<DatasetMutColumnCanBeDiscrete> {
  if (!metadata) return false;

  return (
    metadata.type === DatasetMutColumnType.string ||
    metadata.type === DatasetMutColumnType.number ||
    metadata.type === DatasetMutColumnType.boolean ||
    metadata.type === DatasetMutColumnType.tags
  );
}

export type DatasetMutColumnCanBeComputed =
  | DatasetMutColumnType.string
  | DatasetMutColumnType.number
  | DatasetMutColumnType.boolean
  | DatasetMutColumnType.datetime;

export function isPossiblyComputedColumn(
  metadata: DatasetMutColumn | undefined
): metadata is DatasetMutColumn<DatasetMutColumnCanBeComputed> {
  if (!metadata) return false;

  return (
    metadata.type === DatasetMutColumnType.string ||
    metadata.type === DatasetMutColumnType.number ||
    metadata.type === DatasetMutColumnType.boolean ||
    metadata.type === DatasetMutColumnType.datetime
  );
}
