import { IMoosaSarConfigTag, MMPView } from '@discngine/moosa-models';
import { MultiplePropertiesSelectorPure } from '@discngine/moosa-shared-components';
import {
  buildTag,
  IMultiplePropertiesSelectorProps,
} from '@discngine/moosa-shared-components-gen';
import { Space, Radio, RadioChangeEvent, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  IParameterSelectProps,
  ParameterSelect,
} from 'MoosaMMPChartConfig/ParameterSelect/ParameterSelect';
import React, { FC, useCallback } from 'react';

import {
  GlobalSorting,
  TableBarStyle,
  TableValueStyle,
  IGlobalSorting,
} from '../MoosaMMPChartConfig';
import {
  IViewModeSelectorProps,
  ViewModeSelector,
} from '../ViewModeSelector/ViewModeSelector';

import styles from './MMPViewSettings.module.less';

export type IMMPViewSettingsProps = IMultiplePropertiesSelectorProps &
  IViewModeSelectorProps &
  IParameterSelectProps & {
    availableColumns: string[];
    columnsM: IMoosaSarConfigTag[][];
    globalSorting: IGlobalSorting;
    tableBarStyle: TableBarStyle;
    tableValueStyle: TableValueStyle;
    tableCollapseAllRows: boolean;
    onColumnsChangeM: (columns: IMoosaSarConfigTag[][]) => void;
    onChangeGlobalSortingTypeM: (e: RadioChangeEvent) => RadioChangeEvent;
    onChangeTableBarStyle: (e: RadioChangeEvent) => RadioChangeEvent;
    onChangeTableValueStyle: (e: RadioChangeEvent) => RadioChangeEvent;
    onChangeTableCollapseAllRows: (e: CheckboxChangeEvent) => CheckboxChangeEvent;
  };

export const MMPViewSettings: FC<IMMPViewSettingsProps> = ({
  onSelectViewMode,
  isScoredDataMode,
  viewMode,
  availableColumns,
  availableParamsForScoredDataMode,
  sorting,
  globalSorting,
  invertAxes,
  showRadialMolecules,
  onChangeParameter,
  onChangeSorting,
  parameterID,
  parameters,
  onChangeInvertAxes,
  onChangeShowRadialMolecules,
  onChangeScoredDataMode,
  columns,
  columnsM,
  tableBarStyle,
  tableValueStyle,
  tableCollapseAllRows,
  tableColumnsMap,
  templateColumnsIds,
  onColumnsChange,
  onColumnsChangeM,
  columnLabelMap,
  onChangeGlobalSortingTypeM,
  onChangeTableBarStyle,
  onChangeTableValueStyle,
  onChangeTableCollapseAllRows,
}) => {
  const tagBuilder = useCallback(
    (columnId: string): IMoosaSarConfigTag => {
      return buildTag(columnId, tableColumnsMap, templateColumnsIds);
    },
    [tableColumnsMap, templateColumnsIds]
  );

  return (
    <div className={styles.root}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <ViewModeSelector viewMode={viewMode} onSelectViewMode={onSelectViewMode} />
        {viewMode === MMPView.Table && (
          <>
            <MultiplePropertiesSelectorPure
              availableColumns={availableColumns}
              columnLabelMap={columnLabelMap}
              columns={columns}
              maxTagsInRow={1}
              tagBuilder={tagBuilder}
              onColumnsChange={onColumnsChange}
            />
            <div className={styles.wrapper}>
              <Checkbox
                checked={tableCollapseAllRows}
                onChange={onChangeTableCollapseAllRows}
              >
                Collapse All
              </Checkbox>
            </div>
            <div className={styles.wrapper}>
              <h3 className={styles.title}>Value</h3>
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                value={tableValueStyle}
                onChange={onChangeTableValueStyle}
              >
                <Radio.Button
                  key={TableValueStyle.ABSOLUTE}
                  value={TableValueStyle.ABSOLUTE}
                >
                  ABSOLUTE
                </Radio.Button>
                <Radio.Button key={TableValueStyle.DELTA} value={TableValueStyle.DELTA}>
                  Δ
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className={styles.wrapper}>
              <h3 className={styles.title}>Bars</h3>
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                value={tableBarStyle}
                onChange={onChangeTableBarStyle}
              >
                <Space direction="vertical">
                  <Radio.Button
                    key={TableBarStyle.VERTICAL}
                    value={TableBarStyle.VERTICAL}
                  >
                    Vertical
                  </Radio.Button>
                  <Radio.Button
                    key={TableBarStyle.VERTICAL_SEPARATE}
                    value={TableBarStyle.VERTICAL_SEPARATE}
                  >
                    Vertical Separate
                  </Radio.Button>
                  <Radio.Button
                    key={TableBarStyle.VERTICAL_AXIS}
                    value={TableBarStyle.VERTICAL_AXIS}
                  >
                    Vertical Axis
                  </Radio.Button>
                  <Radio.Button
                    key={TableBarStyle.HORIZONTAL_AXIS}
                    value={TableBarStyle.HORIZONTAL_AXIS}
                  >
                    Horizontal Axis
                  </Radio.Button>
                </Space>
              </Radio.Group>
            </div>
          </>
        )}
        {viewMode === MMPView.MultipleRadiantChart && (
          <>
            <MultiplePropertiesSelectorPure
              availableColumns={availableColumns}
              columnLabelMap={columnLabelMap}
              columns={columnsM}
              maxTagsInRow={1}
              tagBuilder={tagBuilder}
              onColumnsChange={onColumnsChangeM}
            />
            <div className={styles.wrapper}>
              <h3 className={styles.title}>Sort charts</h3>
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                value={globalSorting.type}
                onChange={onChangeGlobalSortingTypeM}
              >
                <Radio.Button
                  key={GlobalSorting.PARAMETER}
                  value={GlobalSorting.PARAMETER}
                >
                  by single parameter
                </Radio.Button>
                <Radio.Button
                  key={GlobalSorting.INDEPENDENT}
                  value={GlobalSorting.INDEPENDENT}
                >
                  independently
                </Radio.Button>
              </Radio.Group>
            </div>
          </>
        )}
        {viewMode === MMPView.RadiantChart && (
          <ParameterSelect
            availableParamsForScoredDataMode={availableParamsForScoredDataMode}
            invertAxes={invertAxes}
            isScoredDataMode={isScoredDataMode}
            parameterID={parameterID}
            parameters={parameters}
            showRadialMolecules={showRadialMolecules}
            sorting={sorting}
            onChangeInvertAxes={onChangeInvertAxes}
            onChangeParameter={onChangeParameter}
            onChangeScoredDataMode={onChangeScoredDataMode}
            onChangeShowRadialMolecules={onChangeShowRadialMolecules}
            onChangeSorting={onChangeSorting}
          />
        )}
      </Space>
    </div>
  );
};
