import { JSXElementConstructor } from 'react';

export type TagId = string;
export type TagsPanel = TagId[][];

export interface RowData {
  type: 'row';
  rowIndex: number;
}
export interface TagData {
  type: 'tag';
  tagId: TagId;
  rowIndex: number;
  colIndex: number;
}
export interface NewRowData {
  type: 'new-row';
}
export interface TagDelimiterData {
  type: 'tag-delimiter';
  rowIndex: number;
  colIndex: number;
}
export type DragData = RowData | TagData | NewRowData | TagDelimiterData;

export interface TagRendererProps {
  tagId: TagId;
  onRemove: () => void;
  className?: string;
}
export type TagRenderer = JSXElementConstructor<TagRendererProps>;

export type DraggableState = 'idle' | 'dragging';

export function isDragData(data: unknown): data is DragData {
  return typeof data === 'object' && data !== null && 'type' in data;
}
export function isTagData(data: unknown): data is TagData {
  return isDragData(data) && data.type === 'tag';
}
export function isTagDelimiterData(data: unknown): data is TagDelimiterData {
  return isDragData(data) && data.type === 'tag-delimiter';
}
export function isRowData(data: unknown): data is RowData {
  return isDragData(data) && data.type === 'row';
}
export function isNewRowData(data: unknown): data is NewRowData {
  return isDragData(data) && data.type === 'new-row';
}
