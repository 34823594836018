import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import DropIndicator from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { classNames } from '@discngine/moosa-common';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { useComponentsContext } from '../ComponentsContext/ComponentsContext';

import styles from './DraggableTags.module.less';
import { Tag } from './Tag';
import { TagDelimiter } from './TagDelimiter';
import { DraggableState, isRowData, isTagData, TagId, TagRenderer } from './types';

interface RowProps {
  tags: TagId[];
  rowIndex: number;
  TagRenderer: TagRenderer;
  onDeleteTag: (tagId: TagId) => void;
}

export const Row: FC<RowProps> = ({ tags, rowIndex, TagRenderer, onDeleteTag }) => {
  const {
    Icons: { DragIcon },
  } = useComponentsContext();

  const rowRef = useRef(null);
  const handleRef = useRef(null);

  /** Draggable */
  const [draggableState, setDraggableState] = useState<DraggableState>('idle');

  useEffect(() => {
    const element = handleRef.current;

    invariant(element);

    return draggable({
      element,
      onDragStart: () => setDraggableState('dragging'),
      onDrop: () => setDraggableState('idle'),
      getInitialData: () => ({ type: 'row', rowIndex }),
    });
  }, [rowIndex]);
  /** End of Draggable */

  /** Droppable */
  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);

  useEffect(() => {
    const element = rowRef.current;

    invariant(element);

    return dropTargetForElements({
      element,
      getData: () => ({ type: 'row', rowIndex }),
      canDrop: ({ source }) => isTagData(source.data) || isRowData(source.data),
      onDragStart: ({ source }) => setIsDraggedOver(isRowData(source.data)),
      onDragEnter: ({ source }) => setIsDraggedOver(isRowData(source.data)),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [rowIndex]);
  /** End of Droppable */

  return (
    <div
      ref={rowRef}
      className={classNames(styles.row, {
        [styles.dragging]: draggableState === 'dragging',
        [styles.isDraggedOver]: isDraggedOver,
      })}
    >
      {isDraggedOver && <DropIndicator edge="top" gap="10px" />}

      <div className={styles.rowInner}>
        <div ref={handleRef} className={styles.rowHandle}>
          <DragIcon />
        </div>

        <div className={styles.rowItems}>
          {tags.map((tagId, colIndex) => (
            <Fragment key={colIndex}>
              <TagDelimiter colIndex={colIndex} rowIndex={rowIndex} />
              <Tag
                key={tagId}
                colIndex={colIndex}
                rowIndex={rowIndex}
                tagId={tagId}
                TagRenderer={TagRenderer}
                onDelete={onDeleteTag}
              />
              {colIndex + 1 === tags.length && (
                <TagDelimiter colIndex={colIndex + 1} rowIndex={rowIndex} />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
