import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { classNames } from '@discngine/moosa-common';
import { FC, useCallback, useEffect } from 'react';

import styles from './DraggableTags.module.less';
import { applyDropUpdate, deleteTag } from './handleDropResult';
import { NewRow } from './NewRow';
import { Row } from './Row';
import { isRowData, isTagData, TagId, TagRenderer, TagsPanel } from './types';

interface TagsProps {
  tags: TagsPanel;
  TagRenderer: TagRenderer;
  maxTagsInRow?: number; // Default value is 3
  className?: string;
  onChange: (tags: TagsPanel) => void;
}

export const DraggableTagsGeneric: FC<TagsProps> = ({
  tags,
  TagRenderer,
  maxTagsInRow = 3,
  className,
  onChange,
}) => {
  useEffect(() => {
    return monitorForElements({
      canMonitor: ({ source }) => isTagData(source.data) || isRowData(source.data),
      onDrop: ({ source, location }) => {
        const target = location.current.dropTargets[0];

        if (!target) return;

        const updated = applyDropUpdate(tags, source.data, target.data, maxTagsInRow);

        if (updated) onChange(updated);
      },
    });
  }, [maxTagsInRow, onChange, tags]);

  const onDeleteTag = useCallback(
    (tagId: TagId) => onChange(deleteTag(tags, tagId)),
    [onChange, tags]
  );

  return (
    <div className={classNames(className, styles.tags)}>
      {tags.map((row, rowIndex) => (
        <Row
          key={rowIndex}
          rowIndex={rowIndex}
          TagRenderer={TagRenderer}
          tags={row}
          onDeleteTag={onDeleteTag}
        />
      ))}

      <NewRow />
    </div>
  );
};
