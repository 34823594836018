import {
  IMultiplePropertiesSelectorProps,
  IMultiplePropertiesSelectorPureProps,
  MultiplePropertiesSelectorGeneric,
  MultiplePropertiesSelectorPureGeneric,
} from '@discngine/moosa-shared-components-gen';

import { AntComponentsProvider } from './AntComponentsProvider/AntComponentsProvider';

export const MultiplePropertiesSelector = (props: IMultiplePropertiesSelectorProps) => (
  <AntComponentsProvider>
    <MultiplePropertiesSelectorGeneric {...props} />
  </AntComponentsProvider>
);

export const MultiplePropertiesSelectorPure = (
  props: IMultiplePropertiesSelectorPureProps
) => (
  <AntComponentsProvider>
    <MultiplePropertiesSelectorPureGeneric {...props} />
  </AntComponentsProvider>
);
